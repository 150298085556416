// stylelint-disable declaration-no-important

// Font weight
.font-weight-semibold  { font-weight: $font-weight-semibold !important; }
.font-weight-black     { font-weight: $font-weight-black !important; }

// Font size
.font-size-lg {
  font-size: 1.625rem !important;

  @include media-breakpoint-down(xs) {
    font-size: 1.5rem !important;
  }
}

.font-size-md { font-size: $font-size-md !important; }
.font-size-sm { font-size: $font-size-sm !important; }
.font-size-xs { font-size: $font-size-xs !important; }

// Text decoration
.text-underline { text-decoration: underline; }

// Text color
.text-dark    { color: $text-dark !important; }
.text-light   { color: $text-light !important; }
.text-lighten { color: $text-lighten !important; }

// No wrap
.no-wrap { white-space: nowrap !important; }

// Display
.display-0 {
  font-size: 8.75rem !important;
}

.display-5 {
  font-size: $display5-size !important;

  @include media-breakpoint-down(xs) {
    font-size: 1.825rem !important;
  }
}

.display-6 {
  font-size: $display6-size !important;
}

.form-text {
  font-size: 85%;
  color: #666c77 !important;
}

@include media-breakpoint-down(xs) {
  .h1,
  h1 {
    font-size: 1.5rem;
  }
}
