// stylelint-disable selector-class-pattern

// Animated
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;

  &.fast {
    animation-duration: .5s;
  }
}

// FadeIn
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

// FadeOut
@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes zoomOut{
  0%{
    opacity: 1;
  }

  100%{
    opacity: 0;
    transform: scale(1.5);
  }
}

.zoomOut {
  animation-name: zoomOut;
}

// FadeInDown
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

// FadeOutUp
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

// Animation Delays
@each $size, $value in $animation-delay {
  .animate#{$size} {
    animation-delay: $value;
  }
}
