// Product
.product {
  &-carousel {
    padding: 10px;
    background-color: $white;
    border: 1px solid rgba($black, .15);
    @include border-radius(6px);

    @include media-breakpoint-down(md) {
      padding: 0;
      background-color: transparent;
      border: 0;
      @include border-radius(0);
    }
  }

  &-img {
    min-height: 600px;
    @include img-cover();

    img {
      @include border-radius(6px);
    }

    @include media-breakpoint-down(md) {
      min-height: 400px;

      img {
        @include border-radius(0);
      }
    }
  }

  @include media-breakpoint-down(md) {
    margin: 0 -15px;
  }
}

@include media-breakpoint-down(md) {
  .product-filter {
    position: fixed;
    top: 0;
    right: -300px;
    z-index: 9;
    width: 300px;
    height: 100vh;
    padding-bottom: 20px;
    overflow: auto;
    background-color: $white;
    display: block !important; /* stylelint-disable-line */
    border-left: 1px solid rgba($black, .08);
    @include transition(.2s ease-in-out all);
  }

  .toggle-filter {
    .toggle-backdrop {
      position: fixed;
      top: 0;
      right: 0;
      z-index: 8;
      width: 100%;
      height: 100vh;
      background-color: rgba($black, .6);
    }

    .product-filter {
      right: 0;
    }
  }
}
