// Easypiechart
.easypiechart {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 110px;
  line-height: 110px;

  &-text {
    display: block;
    font-size: 22px;
    font-weight: $font-weight-semibold;
    color: $white;
  }

  &:hover {
    color: $white;
  }

  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }
}
