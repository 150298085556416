// Accordion
.accordion {
  margin-bottom: 30px;
  border: 1px solid rgba($black, .125);
  box-shadow: 0 2px 0 0 rgba($black, .05);
  @include border-radius(4px);

  &-header {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    padding: 12px 20px;
    font-weight: $font-weight-semibold;
    background-color: #fbfbfb;
    border-bottom: 1px solid rgba($black, .08);
    @include border-top-radius(4px);
  }

  &-item {
    background-color: $white;
    border-bottom: 1px dashed rgba($black, .125);

    &:last-child {
      border: 0;
      @include border-bottom-radius(4px);
    }
  }

  &-text {
    padding: 0 45px 10px;
    font-size: $font-size-base;

    @include media-breakpoint-down(md) {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  &-link {
    display: block;
    padding: 15px 20px;
    margin: 0;
    font-weight: $font-weight-semibold;
    color: darken($body-color, 5%);

    &::before {
      margin-right: 13px;
      font-family: $font-family-icon;
      font-size: 12px;
      content: $ya-minus;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &:focus,
    &:hover {
      color: darken($body-color, 5%);
      background-color: $body-bg;
    }

    &:focus {
      background-color: $white;
    }

    &.collapsed {
      &::before {
        content: $ya-plus;
      }

      &:hover {
        color: darken($body-color, 5%);
        background-color: rgba($black, .025);
      }
    }

    @include media-breakpoint-down(md) {
      padding-top: 13px;
      padding-bottom: 13px;
    }
  }
}

// Default
.accordion-default {
  border: 1px solid rgba($black, .125);

  .accordion-item {
    background-color: transparent;
  }

  .accordion-link {
    &:focus {
      background-color: transparent;
    }
  }
}

// Transparent
.accordion-transparent {
  border: 0;
  box-shadow: none;
  @include border-radius(0);

  .accordion-header {
    background-color: $white;
  }

  .accordion-item {
    background-color: transparent;
  }

  .accordion-text {
    padding-left: 30px;

    @include media-breakpoint-down(md) {
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  .accordion-link {
    padding-left: 5px;

    &:focus {
      background-color: transparent;
    }
  }
}
