// Section
section {
  position: relative;
  padding: 1.5rem 0;

  > .divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    transform: rotate(180deg);

    svg {
      position: relative;
      left: 50%;
      width: calc(100% + 1.3px);
      height: 140px;
      transform: translateX(-50%) rotateY(180deg);
    }

    path {
      fill: $body-bg;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.bg-image {
  position: relative;
  overflow: hidden;
  background-color: #1f1f1f;
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;

  @include media-breakpoint-down(md) {
    .container,
    .container-fluid {
      padding: 0;
    }
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: .2;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  opacity: .8;
}
