// Post
.post {
  padding-bottom: 35px;
  margin-bottom: 40px;
  border-bottom: 1px dashed rgba($black, .12);

  &-title {
    font-weight: $font-weight-bold;

    @include media-breakpoint-down(md) {
      font-size: 1.5rem;
    }
  }

  &-thumbnail {
    margin-bottom: 20px;

    .owl-carousel .owl-dots {
      bottom: 20px;
    }
  }

  p:last-child {
    margin: 0;
  }

  &-header-author {
    display: flex;

    img {
      width: 68px;
      height: 68px;
      margin-right: 15px;
      @include border-radius(50%);

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &-meta {
    margin-bottom: 20px;
    color: #717275;

    &-item {
      white-space: nowrap;

      &::after {
        display: inline-block;
        margin: 0 6px;
        content: "\2022";
      }

      &:last-child::after {
        display: none;
      }

      i {
        margin-right: 5px;
      }
    }
  }
}

// Post single
.post-single {
  margin-bottom: 20px;
  border: 0;

  .post-header-align {
    margin-left: -85px;
  }

  .post-thumbnail,
  .blockquote {
    margin-right: -95px;
    margin-left: -95px;

    @include media-breakpoint-down(md) {
      margin: 0 -15px;
    }
  }

  .post-thumbnail {
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }

  .post-body {
    ul,
    .blockquote {
      margin-bottom: 30px;
    }

    .blockquote {
      margin-top: 30px;
    }

    p > a {
      color: $primary;
      text-decoration: underline;

      &:hover {
        color: darken($primary, 5%);
      }
    }
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 20px;
    margin-bottom: 0;

    .post-title {
      font-size: 1.5rem;
    }

    .post-body,
    .post-header {
      padding: 0 .25rem;
    }

    .blockquote {
      margin: 0 -19px;
    }
  }
}

// Post medium
.post-medium {
  display: flex;
  padding-bottom: 25px;
  margin-bottom: 25px;

  .post-meta {
    margin-bottom: 15px;
  }

  .post-thumbnail {
    position: relative;
    width: 30%;
    min-height: 200px;
    margin-bottom: 0;
    @include img-cover();
  }

  .post-body {
    width: 75%;
    padding-left: 20px;
  }

  @include media-breakpoint-down(sm) {
    display: block;

    .post-thumbnail {
      display: block;
      width: 100% !important; /* stylelint-disable-line */
      margin-bottom: 20px;
    }

    .post-body {
      width: 100%;
      padding-left: 0;
    }
  }
}

// Review
.post-review {
  .post-thumbnail {
    width: 22%;

    img {
      position: inherit;
    }
  }

  .post-body {
    width: 78%;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .badge {
    padding: 5px 13px;
    font-size: $font-size-sm;
    @include border-radius(30px);
  }
}

// Tags
.tags {
  a {
    display: inline-block;
    padding: 5px 8px;
    margin-right: 2px;
    font-size: $font-size-sm;
    font-weight: $font-weight-semibold;
    color: darken($primary, 5%);
    background-color: rgba($white, .5);
    border: 1px solid rgba($black, .12);
    @include border-radius(3px);

    &:hover {
      color: inherit;
      background-color: $white;
      border: 1px solid rgba($black, .2);
      box-shadow: 0 1px 3px 0 rgba($black, .08);
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 8px;
    }
  }

  &-secondary a {
    color: inherit;
    background-color: $body-bg;
    border: 1px solid rgba($black, .1);
  }

  &-sm a {
    font-size: 13px;
  }
}
