// Embed
.embed {
  background-color: $dark;

  &-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  &-icon i,
  &::after {
    position: relative;
    z-index: 3;
    display: inline-block;
    width: $embed-icon-width;
    height: $embed-icon-height;
    font-family: $font-family-icon;
    font-size: 28px;
    line-height: $embed-icon-height;
    color: $embed-icon-color;
    text-align: center;
    cursor: pointer;
    content: $ya-play;
    background-color: $embed-icon-bg;
    box-shadow: 0 1px 25px 0 rgba($black, .2);
    @include border-radius(50%);
    @include transition($transition-base);

    @include media-breakpoint-down(xs) {
      width: $embed-icon-width / 1.1;
      height: $embed-icon-height / 1.1;
      font-size: 25px;
      line-height: $embed-icon-height / 1.1;
    }
  }

  &-icon:hover i,
  &:hover::after {
    box-shadow: 0 1px 25px 0 rgba($black, .2);
    opacity: .85;
  }

  &.play::after {
    animation-name: zoomOut;
    animation-duration: .5s;
    animation-fill-mode: both;
  }

  &-title {
    position: absolute;
    top: 25px;
    left: 30px;
    z-index: 3;
    padding: 10px 18px;
    font-size: 16px;
    font-weight: $font-weight-semibold;
    color: $white;
    background-color: $dark;
    border-left: 3px solid $primary;
    @include border-radius(30px);

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  &-responsive {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-length {
    position: absolute;
    right: 30px;
    bottom: 25px;
    z-index: 3;
    display: inline-block;
    padding: 6px 10px;
    font-size: $font-size-md;
    color: $white;
    background-color: $dark;
    @include border-radius(30px);
  }

  &-icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;

    i {
      &::before {
        margin-right: -2px;
      }

      &-dark i {
        color: $white;
        background-color: darken($dark, 5%);
      }
    }
  }

  &-icon-dark::after {
    color: $white;
    background-color: darken($dark, 5%);
  }
}

// Embed player
.embed-player {
  .embed-icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 75px;
      height: 75px;
      font-size: 22px;
      color: darken($gray, 20%);
      cursor: pointer;
      background-color: $white;
      @include transition($transition-base);
      box-shadow: 0 1px 25px 0 rgba($black, .2);
      @include border-radius(50%);

      &::before {
        margin-right: -2px;
      }

      &:hover {
        box-shadow: 0 1px 25px 0 rgba($black, .2);
        opacity: .85;
      }
    }

    &-dark i {
      color: $white;
      background-color: darken($dark, 5%);
    }
  }

  .embed-length {
    right: 15px;
    bottom: 15px;
    font-size: $font-size-md;
  }

  &-lg {
    .embed-img {
      height: 600px;
      @include img-cover();
    }

    .embed-icon {
      i {
        width: 90px;
        height: 90px;
        font-size: 26px;
      }
    }
  }
}

// Embed action
.embed-action {
  position: absolute;
  right: 15px;
  bottom: 15px;
  display: flex;

  &-item {
    display: flex;
    padding: 10px 9px;
    margin-left: 5px;
    font-size: $font-size-md;
    color: rgba($white, .8);
    text-align: center;
    cursor: pointer;
    background-color: darken($dark, 9%);

    &:hover {
      color: $white;
    }
  }
}

// Embed gallery
.embed-gallery {
  @include clearfix();

  .embed-image {
    float: left;

    &:first-child {
      width: 40%;
    }

    &:nth-child(2) {
      width: 60%;

      img {
        padding-left: 10px;
      }
    }

    &:last-child {
      width: 100%;
      height: 370px;
      margin-top: 10px;
    }
  }
}
