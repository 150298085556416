// Widgets
.widget {
  margin-bottom: 45px;

  &-header {
    padding: 5px 10px;
    margin-bottom: 20px;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    border-left: 3px solid $primary;
  }

  &-img {
    height: 240px;
    @include img-cover;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 25px;

    &:first-child {
      margin-top: 20px;
    }

    &:last-child {
      margin: 0;
    }
  }
}

// Widget list
.widget-list {
  .media {
    padding: 12px 0;
    border-bottom: 1px dashed rgba($black, .08);
  }

  .img-cover {
    width: 100px;
    height: 80px;
    margin-right: 10px;

    &.img-md {
      width: 150px;
      height: 100px;
    }

    &.img-lg {
      width: 200px;
      height: 110px;

      @include media-breakpoint-down(xs) {
        width: 150px;
      }
    }
  }
}

// Widget video
.widget-video {
  .widget-meta {
    position: absolute;
    right: 15px;
    bottom: 13px;
    z-index: 2;
    display: inline-block;
    padding: 5px 8px;
    font-size: 13px;
    font-weight: $font-weight-light;
    color: $white;
    background-color: $dark;
    @include border-radius(30px);
  }
}

// Widget gallery
.widget-gallery {
  .widget-img {
    height: 150px;
    margin-bottom: 6px;
  }
}

// Widget game
.widget-game {
  position: relative;
  color: rgba($white, .7);
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;

  h6 {
    color: $white;
  }

  .badge {
    padding: 7px 10px;
    @include border-radius(30px);
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .1;
  }

  a:hover {
    color: $white;
    text-decoration: underline;
  }

  .btn:hover {
    text-decoration: none;
  }

  .widget-title {
    margin-bottom: 0;
    font-weight: $font-weight-bold;
  }

  .widget-body {
    position: relative;
    z-index: 2;
    padding: 25px;
  }
}

// Widget users
.widget-users {
  .widget-body {
    > a {
      position: relative;
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 5px;

      img {
        width: 55px;
        height: 55px;
        @include border-radius(50%);
      }

      .badge {
        position: absolute;
        right: 1px;
        bottom: 0;
        display: inline-block;
        padding: 5px;
        border: 2px solid $body-bg;
        @include border-radius(50%);
      }
    }
  }

  .widget-user-count {
    position: relative;

    span {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 55px;
      height: 55px;
      font-size: $font-size-sm;
      font-weight: $font-weight-semibold;
      color: $white;
      background-color: rgba($primary, .85);
      @include border-radius(50%);
    }
  }
}

// Widget secondary
.widget-secondary {
  padding: 0;
  background-color: $white;
  border: 1px solid rgba($black, .125);
  box-shadow: 0 2px 0 0 rgba($black, .05);
  @include border-radius(4px);

  .widget-header {
    padding: 12px 15px;
    margin: 0;
    border-bottom: 1px solid rgba($black, .08);
    border-left: 0;
  }

  .widget-body {
    padding: 15px;
  }
}

// Widget recommends
.widget-recommends {
  .widget-user {
    position: relative;
    padding-bottom: 13px;
    margin-bottom: 10px;
    border-bottom: 1px dashed rgba($black, .15);

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }

    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      @include border-radius(50%);
    }
  }
}

// Widget tags
.widget-tags {
  .tags > a {
    margin-bottom: 8px;
  }
}
