// stylelint-disable declaration-no-important

// Overflow
.overflow-auto   { overflow: auto; }
.overflow-hidden { overflow: hidden; }

// Alignment
.bottom { bottom: 0; }
.top { top: 0; }
.left { left: 0; }
.right { right: 0; }
