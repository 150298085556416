// Custom label
.custom-control-label {
  display: block;

  &::before {
    top: 1.5px;
    left: -30px;
    width: 20px;
    height: 20px;
    background-color: $white;
    border: 1px solid #d4d4d4;
    box-shadow: none !important; // stylelint-disable-line declaration-no-important
    @include transition(none);
  }

  &::after {
    top: 1.5px;
    left: -30px;
    width: 20px;
    height: 20px;
  }
}

.custom-radio .custom-control-input:checked,
.custom-checkbox .custom-control-input:checked {
  & ~ .custom-control-label::before {
    border-color: $primary;
  }
}

.custom-control {
  padding-left: $custom-control-gutter;
}

.form-control-lg {
  font-size: 18px;
}

.form-box {
  overflow: auto;
}

// Custom file
.custom-file-btn {
  width: auto;
  height: auto;
  cursor: pointer;

  .custom-file-input {
    width: auto;
    height: auto;
    cursor: pointer;
  }

  .custom-file-label {
    height: auto;
    padding: 0;
    margin: 0;
    cursor: pointer;
    background: none;
    border: none;

    &::after {
      display: none;
    }
  }
}

// Form inline
.form-control-inline {
  min-width: 25rem;

  @include media-breakpoint-down(lg) {
    min-width: 2rem;
  }

  @include media-breakpoint-down(md) {
    min-width: auto;
  }
}

@include media-breakpoint-down(sm) {
  .form-inline .input-group {
    width: 100%;
  }
}

// Dropload
.dropload {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 180px;
  color: rgba($black, .4);
  text-align: center;
  cursor: pointer;
  border: 1px dashed rgba($black, .2);

  &:hover {
    border: 1px dashed rgba($black, .3);
  }

  i {
    margin-top: 23px;
    margin-bottom: 10px;
    font-size: 32px;
    color: rgba($black, .3);
  }

  &-text {
    display: block;
    font-weight: $font-weight-semibold;
  }
}
