// Progress
.progress {
  box-shadow: inset 0 1px 2px rgba($black, .1);
}

// Progress sizes
.progress-lg {
  height: 14px;
}

.progress-sm {
  height: 8px;
}

// Progress text
.progress-text {
  display: flex;

  p {
    margin-bottom: .5rem;
  }
}
