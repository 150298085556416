// Lightbox
.ya-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .embed-responsive {
    width: 80%;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
      width: 90%;
    }
  }

  .owl-carousel {
    max-width: 80%;
    margin: 0 auto;

    .owl-item img {
      width: 100%;
      max-width: 100%;
    }
  }

  &-item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &-img {
    position: relative;
    z-index: 100;
    max-width: 80%;
    margin: 0 auto;
    box-shadow: 0 3px 10px 0 rgba($black, .1);

    @include media-breakpoint-down(md) {
      max-width: 95%;
    }
  }

  .ya-lightbox-comment {
    overflow: hidden;

    &.ya-lightbox-gallery {
      padding: 0 60px;
    }

    .owl-carousel {
      width: 100%;
      max-width: 100%;
    }

    .embed-responsive {
      width: 90%;
      height: 90%;
    }
  }
}

// Lightbox body
.ya-lightbox-body {
  z-index: 9;
  display: flex;
  width: 100%;
  height: 100%;
}

// Lightbox
.ya-lightbox-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  background-color: $lightbox-bg;
}

// Lightbox close
.ya-lightbox-close {
  position: absolute;
  top: 15px;
  right: 30px;
  z-index: 9;
  cursor: pointer;
  opacity: .8;

  &:hover {
    opacity: 1;
  }

  &::before {
    font-family: $font-family-icon;
    font-size: 20px;
    color: $white;
    content: $ya-times;
  }

  @include media-breakpoint-down(md) {
    right: 15px;
  }
}

// Lightbox Comments
.ya-lightbox-comments {
  width: 480px;
  height: 100%;
  padding: 5px 20px;
  margin-left: auto;
  overflow: auto;
  background-color: $white;

  @include media-breakpoint-down(md) {
    display: none;
  }
}
