@mixin img-cover {
  position: relative;
  display: block;
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
  @include border-radius(0);

  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
}
